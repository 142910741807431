<template>
  <div class="tjData">
    <div class="navTitle"><img src="@/assets/img/arrl.png" alt="" @click="goBack">统计数据</div>
    <div class="data-div">
      <div v-if="store.state.pageCityCode === '450000'" class="data-box">
        <img src="../../assets/img/tjsj1.png" alt="">
        <div class="data-data">
          <p class="title">入驻城市</p>
          <p class="data"><span class="yellow">{{data.enterCityCnt}}</span>&nbsp;座</p>
        </div>
      </div>
      <div class="data-box">
        <img src="../../assets/img/tjsj2.png" alt="">
        <div class="data-data">
          <p class="title">注册企业</p>
          <p class="data"><span class="yellow">{{data.registerCnt}}</span>&nbsp;家</p>
        </div>
      </div>
      <div v-if="store.state.pageCityCode != '450000'" class="data-box">
        <img src="../../assets/img/tjsj2.png" alt="">
        <div class="data-data">
          <p class="title">融资企业</p>
          <p class="data"><span class="yellow">{{data.financeEnterCnt}}</span>&nbsp;家</p>
        </div>
      </div>
      <div class="data-box">
        <img src="../../assets/img/tjsj3.png" alt="">
        <div class="data-data">
          <p class="title">银行网点</p>
          <p class="data"><span class="yellow">{{data.branchBankCnt}}</span>&nbsp;个</p>
        </div>
      </div>
      <div class="data-box">
        <img src="../../assets/img/tjsj4.png" alt="">
        <div class="data-data">
          <p class="title">融资笔数</p>
          <p class="data"><span class="yellow">{{data.financeCnt}}</span>&nbsp;笔</p>
        </div>
      </div>
      <div class="data-box">
        <img src="../../assets/img/tjsj5.png" alt="">
        <div class="data-data">
          <p class="title">融资金额</p>
          <p class="data"><span class="yellow">{{data.financeAmtSum}}</span>&nbsp;亿</p>
        </div>
      </div>
      <div class="data-box">
        <img src="../../assets/img/tjsj6.png" alt="">
        <div class="data-data">
          <p class="title">信贷产品</p>
          <p class="data"><span class="yellow">{{data.creditProductCnt}}</span>&nbsp;个</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useStore } from 'vuex';
import { Toast,Dialog } from 'vant';
import { apiIndexData } from '../../api/axios';
import { ref } from 'vue';
export default {
   setup() {
     let store = useStore();
     const data = ref({});
     apiIndexData({}).then(res => {                 
             if(res.code == '1'){
                 data.value = res.info.statisticsDatas; 
              }
              else {
                Toast(res.msg);
              }
          });  
    return {
      store,
      data
     };
  },
  methods:{
    goBack(){
        this.$router.go(-1);
    },
  }
}
</script>
<style lang="less" scoped>
.tjData {
  padding-top: 12.5vw;
  .data-div {
    margin: 4vw;
    display: flex;
    flex-wrap: wrap;
    .data-box {
      padding: 4vw 1vw 4vw 4vw;
      width: 39vw;
      background-color: #fff;
      border-radius: 2vw;
      display: flex;
      align-items: center;
      margin-bottom: 4vw;
      box-shadow: 0 0 3vw rgba(0,0,0,0.1);
      img {
        width: 12vw;
      }
      .data-data {
        border-left: 1px solid #ccc;
        padding-left: 2vw;
        margin-left: 2vw;
        p.title {
          font-size: 4.5vw;
          font-weight: 600;
        }
        p.data {
          margin-top: 1vw;
          font-size: 4vw;
          color: #999;
          .yellow {
            color: #fd9f4a;
          }
        }
      }
      
    }
    .data-box:nth-child(odd) {
        margin-right: 4vw;
      }
  }
}
</style>
